<template>
  <div>
    <Loading v-show="show" />
    <div class="breadcrumb-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadcrumb-text">
              <h1 class="text-center" id="postTitleId"></h1>
              <!-- <p id="postSubTitleId" class="text-center"></p> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about-page-area padding-top-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-12 col-12">
            <div class="skill-image">
              <img loading="lazy" alt="" id="postImageId" />
            </div>
          </div>
          <div class="col-lg-8 col-md-12 col-12">
            <div class="about-text-container">
              <!-- <p id="postTitleId"></p> -->
              <p id="postSubTitleId" class="subJob"></p>
              <a
                v-show="showDownload"
                id="downloadIssue10"
                class="downloadIssu"
                target="_blank"
                >{{ DownloadFile }}</a
              >
              <!-- <p id="postDetailsId"></p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-page-area padding-bottom-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="about-text-container">
              <p id="postDetailsId"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "./Loading";

import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      show: true,
      showDownload: true,
      DownloadFile: "",
      ArTranslat: ArJson,
      EnTranslat: EnJson,
    };
  },
  methods: {
    getApis() {
      var bodyFormData = new FormData();
      var self = this;
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
        self.direction = "right";
      } else {
        self.translate = self.EnTranslat;
        self.direction = "left";
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.DownloadFile = self.translate[0]["Home"]["DownloadFile"];
      bodyFormData.append("check", "getPostById");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("PostId", this.$route.params.id);
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", this.$route.params.type); //يجب تعديله لانه خاطى
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "10");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData,
      })
        .then(function (response) {
          var arr = response.data;
          if (arr.length != 0) {
            document
              .getElementById("postImageId")
              .setAttribute(
                "src",
                "https://api2.yuniv.net:444/Images/post/" +
                  response.data[0]["postImage"]
              );
            document.getElementById("postTitleId").innerText =
              response.data[0]["postTitle"];
            document.getElementById("postSubTitleId").innerText =
              response.data[0]["postSubTitle"];
            document.getElementById("postDetailsId").innerHTML =
              response.data[0]["postDetails"];
            if (response.data[0]["postFile"]) {
              self.showDownload = true;
              document
                .getElementById("downloadIssue10")
                .setAttribute(
                  "href",
                  "https://api2.yuniv.net:444/images/post/" +
                    response.data[0]["postFile"]
                );
            } else {
              self.showDownload = false;
            }
          }
          document.getElementById("VueMainJsNewTouch")?.remove();
          let recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute("src", "/js/main.js");
          recaptchaScript.id = "VueMainJsNewTouch";
          document.head.appendChild(recaptchaScript);
          setTimeout(() => {
            self.show = "false";
            document.querySelectorAll("div.loader").forEach(function (element) {
              element.setAttribute("hidden", true);
            });
          }, 100);
        })
        .catch(function () {
          // console.log("error", response);
        });
    },
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;

    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      })
        .then(function (response) {
          self.lang = response.data[0]["LangId"];
          localStorage.setItem("lang", response.data[0]["LangId"]);
          if (response.data[0]["LangId"] == "Ar") {
            document.getElementById("langfilewebsites")?.remove();
            self.getApis();
          } else {
            self.translate = self.EnTranslat;
            let link1 = document.createElement("link");
            link1.setAttribute("rel", "stylesheet");
            link1.id = "langfilewebsites";
            link1.setAttribute("href", "../../../style-ltr.css");
            document.head.appendChild(link1);
            self.getApis();
          }
        })
        .catch(function () {
          // console.log("error", response);
        });
    } else {
      if (localStorage.getItem("lang") == "Ar") {
        document.getElementById("langfilewebsites")?.remove();
        self.getApis();
      } else {
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "../../../style-ltr.css");
        document.head.appendChild(link1);
        self.getApis();
      }
    }
  },
};
</script>
<style scoped>
.padding-top-area {
  padding-top: 50px;
  padding-bottom: 20px;
}
.padding-bottom-area {
  padding-top: 20;
  padding-bottom: 80px !important;
}

.downloadIssu {
  color: rgb(0, 140, 255);
}

.downloadIssu:hover {
  color: rgb(71, 73, 75);
}
</style>
